<template>
    <b-row class="match-height">
      <b-col lg="12">
        <b-card title="Invoice Detail">
          <b-row>
            <b-col cols="12">
              <b-button
                type="button"
                variant="primary"
                class="mr-1"
                @click="previous()"
              >
                <feather-icon
                  icon="ArrowLeftIcon"
                  size="16"
                  class="align-middle"
                />
                Back
              </b-button>
              <div class="devider" />
            </b-col>
            <b-col cols="12">
              <b-form-group
                label="Customer "
                label-for="customer"
                label-cols-md="12"
              >
                <b-form-input
                  id="customer"
                  v-model="customer"
                  placeholder="Customer"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Job Number"
                label-for="jobnumber"
                label-cols-md="12"
              >
                <b-form-input
                  id="jobnumber"
                  v-model="jobnumber"
                  placeholder="Job Number"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Invoice No "
                label-for="invoiceno"
                label-cols-md="12"
              >
                <b-form-input
                  id="invoiceno"
                  v-model="invoiceno"
                  placeholder="Invoice No"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Invoice Date "
                label-for="invoicedate"
                label-cols-md="12"
              >
                <b-form-input
                  id="invoicedate"
                  v-model="invoicedate"
                  placeholder="Invoice Date"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Ref No "
                label-for="refno"
                label-cols-md="12"
              >
                <b-form-input
                  id="refno"
                  v-model="refno"
                  placeholder="Ref No"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="Agreement Ref"
                label-for="agreementref"
                label-cols-md="12"
              >
                <b-form-input
                  id="agreementref"
                  v-model="agreementref"
                  placeholder="Agreement Ref"
                  readonly
                />
              </b-form-group>
              <b-form-group
                label="No Invoice Pusat"
                label-for="noinvoicepusat"
                label-cols-md="12"
                readonly
              >
                <b-form-input
                  id="noinvoicepusat"
                  v-model="noinvoicepusat"
                  placeholder="No Invoice Pusat"
                  readonly
                />
              </b-form-group>
  
              <b-form-group
                label="Input Date"
                label-for="inputdate"
                label-cols-md="12"
                readonly
              >
                <b-form-input
                  id="inputdate"
                  v-model="inputdate"
                  readonly
                />
              </b-form-group> 
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </template>
  <script>
  import {
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BButton,
    BCard,
    BListGroup,
    BListGroupItem,
  } from "bootstrap-vue"
  import axios from "axios"
  import moment from "moment"
import { getToken } from '@/auth/utils'
  import Ripple from "vue-ripple-directive"
  
const customer = ""
const jobnumber = ""
const invoiceno = ""
const invoicedate = ""
const refno = ""
const agreementref = ""
const noinvoicepusat = ""
const inputdate = ""
  export default {
    components: {
      BListGroup,
      BListGroupItem,
      BCard,
      BRow,
      BCol,
      BFormGroup,
      BFormInput,
      BForm,
      BButton,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        customer,
        jobnumber,
        invoiceno,
        invoicedate,
        refno,
        agreementref,
        noinvoicepusat,
        inputdate,
      }
    },
    mounted() {
      this.getDetail()
    },
    methods: {
      getDetail() {
      const userToken = getToken()
        const invDet = localStorage.getItem("invdet")
        const headers = {
          "Content-Type": "application/json",
          'X-Token-Access': `Bearer ${userToken}`,
        }
        const body = {
            InvoiceNumber: invDet,
        }
      const url = `${process.env.VUE_APP_API_URL}${process.env.VUE_APP_API_GET_INVOICE}/getdetail`
      axios
        .post(url, body, { headers })
        .then(response => {
            this.customer = response.data.Payload.Customer
            this.jobnumber = response.data.Payload.JobNo
            this.invoiceno = response.data.Payload.InvoiceNumber
            this.invoicedate = moment(String(response.data.Payload.InvoiceDate)).format("YYYY-MM-DD")
            this.refno = response.data.Payload.Reference
            this.agreementref = response.data.Payload.Agreement
            this.noinvoicepusat = response.data.Payload.InvoicePusat !== null ? response.data.Payload.InvoicePusat : "-"
            this.inputdate = response.data.Payload.InvoicePusatDate !== null ? response.data.Payload.InvoicePusatDate : "-"
        })
      },
      getOptionLabel(option) {
        return (option && option.label) || ""
      },
      putItem(val) {
        if (this.chosens.length > 0) {
          let same = false
          this.chosens.map(elem => {
            if (elem.value === val.value) {
              same = true
            }
          })
          if (same === false) {
            let item = null
            this.items.map(elem => {
              if (elem.value === val.value) {
                item = { label: elem.label, value: elem.value, qty: 1 }
              }
            })
            this.chosens.push(item)
          }
        } else {
          let item = null
          this.items.map(elem => {
            if (elem.value === val.value) {
              item = { label: elem.label, value: elem.value, qty: 1 }
            }
          })
  
          this.chosens.push(item)
        }
      },
      applyItem(val) {
        console.log(val.data)
      },
      getOptionKey(option) {
        return (option && option.value) || ""
      },
      inputSearch(search) {
        console.log(search)
      },
      removeRow(val) {
        this.chosens = this.chosens.filter(obj => obj.value !== val)
      },
      dateFormat(value, format = "MM/DD/YYYY") {
        return moment(String(value)).format(format)
      },
      dateSimple(value, format = "YYYY-MM-DD") {
        let dateRet = ""
        if (value !== null) {
          dateRet = moment(String(value)).format(format)
        } else {
          dateRet = null
        }
        return dateRet
      },
      previous() {
        this.$router.go(-1)
      },
      numberFormat(value) {
        return parseFloat(value).toFixed(2)
      },
      capitalizeWords(str) {
        // Split the string into words using spaces as the separator
        const words = str.split(" ")
        // Capitalize the first letter of each word and join them back together
        const capitalizedWords = words.map(word => {
          return word.charAt(0).toUpperCase() + word.slice(1)
        })
        // Join the capitalized words into a single string with spaces
        return capitalizedWords.join(" ")
      },
      numFormat(num) {
      // Convert the number to a string
      const numberString = String(num)
      // Split the string into integer and fractional parts
      const [integerPart, fractionalPart = ''] = numberString.split('.')
      // Add comma separators to the integer part
      const integerWithCommas = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      // Return the formatted number with the fractional part
      return fractionalPart ? `${integerWithCommas}.${fractionalPart}` : integerWithCommas
  },
   roundToTwoDecimals(num) {
      return (Math.round(num * 100) / 100).toFixed(2)
  },
    },
  }
  </script>  